<template>
  <div class="table-responsive">
    <table class="table table-borderless table-hover">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Rarity</th>
        <th scope="col">Type</th>
        <th scope="col">Att.</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items" :key="index"
          :class="{ 'table-primary': selectedItem && selectedItem === item }"
          @click="selectRow(item)">
        <th scope="row">{{ item.name }}</th>
        <td><RarityPill :rarity=item.rarity></RarityPill></td>
        <td>{{item.displayType()}}</td>
        <td>
          <span v-if="item.attunement.isRequired" class="attunement-tick">x</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RarityPill from "@/components/RarityPill.vue";
export default {
  name: "HomebrewTable",
  components: {RarityPill},
  props: {
    items: Array,
    selectedItem: Object,
  },
  methods: {
    selectRow(item) {
      this.$emit('item-selected', item);
    }
  }
};
</script>

<style>
.table-hover tbody tr:hover {
  cursor: pointer;
}

.table th, .table td {
  font-size: smaller; /* You can also use a specific size like 0.8em, 12px, etc. */
  text-align: left;   /* Aligning all cell text to the left */
}

.centered-cell {
  text-align: center;
}
</style>
