<template>
  <span :class="['tag', damageTypeClass]">
    {{ tag }}
    <span class="remove-tag" @click="removeTag">&times;</span>
  </span>
</template>

<script>
import {damageTypes} from "@/model/item.js";

export default {
  name: "DamageTag",
  props: {
    tag: String,
    index: Number
  },
  methods: {
    removeTag() {
      this.$emit('remove-tag', this.index);
    }
  },
  computed: {
    damageTypeClass() {
      if(damageTypes.includes(this.tag)){
        return 'bg-color-dmg-'+this.tag;
      }
      return 'bg-color-dmg-bludgeoning';
    }
  }
}
</script>

<style scoped>
.tag {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: white;
  font-size: small;
}

.remove-tag {
  margin-left: 5px;
  cursor: pointer;
  color: white;
}
</style>
