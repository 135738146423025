<template>
  <div class="modal hidden" ref="modal">
    <div class="modal-container">
      <HomebrewItemEditor
          :selected-item="selectedItem"
          :is-modal-open="isModalOpen"
          @update-item="this.updateEditableItem"
      ></HomebrewItemEditor>
    </div>
  </div>
  <div v-if="checkCookie(this.loggedInCookieName)">
    <div class="container">
      <h1 class="head">Homebrew Items</h1>
    </div>
    <div class="container buttons-container">
      <button type="button" class="btn btn-outline-primary" @click.prevent=addNewItem>
        Add New
      </button>
    </div>
    <div class="container homebrew-container">
      <HomebrewTable
          :items="items"
          @item-selected="selectItem"
          :selected-item="selectedItem"
          class="homebrew-table"
      ></HomebrewTable>
      <HomebrewDetails
          :selected-item="selectedItem"
          @edit-selected-item="editSelectedItem"
          class="homebrew-details"
      ></HomebrewDetails>
    </div>
  </div>
  <div v-else>
    <JumbotronFallback401 class="fallback"></JumbotronFallback401>
  </div>
</template>

<script>
import HomebrewTable from '@/components/HomebrewTable.vue';
import HomebrewDetails from '@/components/HomebrewDetails.vue';
import axios from 'axios';
import router from "@/router";
import Item from "@/model/item.js";
import JumbotronFallback401 from "@/components/JumbotronFallback401.vue";
import HomebrewItemEditor from "@/components/HomebrewItemEditor";
import {config} from "@/model/config.js";

export default {
  name: "HomebrewTableView",
  components: {
    HomebrewItemEditor,
    JumbotronFallback401,
    HomebrewTable,
    HomebrewDetails,
  },
  data() {
    return {
      items: [],
      selectedItem: null,
      loading: false,
      error: null,
      loggedInCookieName: 'logged-in-as',
      isModalOpen: false,
      config: config,
    };
  },
  methods: {
    editSelectedItem(e){
      e.stopPropagation()
      this.showEditItemModal()
    },
    showEditItemModal(){
      const modal = this.$refs.modal;
      document.body.style.overflow = 'hidden';
      modal.classList.remove("hidden");
      this.isModalOpen = true;
    },
    hideEditItemModal(){
      const modal = this.$refs.modal;
      document.body.style.overflow = '';
      modal?.classList.add("hidden");
      this.isModalOpen = false;
    },
    addNewItem() {
      this.loading = true;
      axios.post(config.apiURL+'/homebrew/items', [new Item()], {withCredentials: true})
          .then(() => {
            console.log("Successfully adding new item")
            this.fetchData(() => {if(!this.selectedItem) return; this.changeSelection(this.selectedItem.id)})
          })
          .catch(error => {
            console.error('Error adding item:', error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    updateEditableItem(editableItem) {
      this.loading = true;
      axios.put(config.apiURL+'/homebrew/items', [editableItem], {withCredentials: true})
          .then(() => {
            console.log("Successfully updated item")
            this.fetchData(() => {if(!this.selectedItem) return; this.changeSelection(this.selectedItem.id)})
          })
          .catch(error => {
            console.error('Error updating data:', error);
          })
          .finally(() => {
            this.loading = false;
          });
    },
    fetchData(callback) {
      this.loading = true;
      axios.get(config.apiURL+'/homebrew/items', {withCredentials: true})
          .then(response => {
            this.items = response.data.items.map(itemData => new Item(itemData));
            if(callback) callback()
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.error = 'Failed to fetch data';
          })
          .finally(() => {
            this.loading = false;
          });
    },
    selectItem(item) {
      this.changeSelection(item.id)
      router.push({ name: 'Items', query: { id: item.id } })
    },
    changeSelection(itemID) {
      if (!itemID){
        this.selectedItem = null
        return
      }
      this.selectedItem = this.items.find(item => item.id.toString() === itemID.toString());
    },
    checkCookie(cookieName) {
      const cookieValue = document.cookie
          .split('; ')
          .find(row => row.startsWith(cookieName + '='));
      return !!cookieValue;
    },
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(itemID) {
        this.fetchData(() => {this.changeSelection(itemID)});
      }
    }
  },
  mounted() {
    // const itemID = this.$route.query.id;
    // this.fetchData(() => {this.changeSelection(itemID)});
    // Select the modal and its container
    this.$nextTick(() => {
      const modal = this.$refs.modal;

      if (modal) {
        window.addEventListener("click", (e) => {
          if (modal === e.target) {
            this.hideEditItemModal()
          }
        });
      }
    });
  }
};
</script>

<style scoped>
.container {
  display: flex;
}

.buttons-container {
  align-items: start;
  justify-content: start;
}

.homebrew-container {
  align-items: start;
}

.homebrew-table {
  width: 60%;
}

.homebrew-details {
  position: sticky;
  top: 6vh;
  max-height: 94vh;
  overflow-y: auto;
}

/*!* Hide scrollbar for Chrome, Safari, Opera, and modern Edge *!*/
/*.homebrew-details::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

/*!* Hide scrollbar for Firefox *!*/
/*.homebrew-details {*/
/*  -ms-overflow-style: none;  !* IE and Edge *!*/
/*  scrollbar-width: none; !* Firefox *!*/
/*}*/

.fallback {
  margin: 10vh 15vw 10vh 15vw;
  padding: 10vh 10vw 10vh 10vw;
}

.head {
  font-weight: bold;
  color: #ababab;
  text-align: left;
}

.modal.hidden {
  display: none;
}

.modal {
  display: block; /* Or another appropriate display type */
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  backdrop-filter: blur(8px); /* Adjust the px value to increase/decrease blur */
}

.modal-container {
  background-color: white;
  margin: 10vh 10vw 10vh 10vw; /* Center vertically */
  padding: 20px;
  width: 80vw;
  max-width: 80vw;
  height: 80vh;
  max-height: 80vh;
  overflow: auto; /* Enable scroll if needed */
  border-radius: 5px;
}
</style>
