<template>
  <div class="data-container" v-if="selectedItem">
    <div>
      <img v-if="imageExists"
           @error="imageExists = false"
           :src="`${config.apiURL}/homebrew/image/items/${selectedItem.id}/featured/dashboard-preview.png`"
           alt="Item Image"
           class="item-image bg-color-homebrew-image"/>

      <div v-else class="item-image-placeholder bg-color-homebrew-image">
        <p class="item-image-placeholder-content">Image not available</p>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div class="item-name">{{ selectedItem.name }}</div>
      <button class="btn btn-outline-primary edit-button" @click="$emit('edit-selected-item', $event)">
        <font-awesome-icon icon="pencil-alt" />
      </button>
    </div>
    <div class="item-summary">{{ selectedItem.summary() }}</div>
    <br>

    <!-- Tab Navigation -->
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" :class="{ active: activeTab === 'description' }" href="#" @click.prevent="activeTab = 'description'">Description</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{ active: activeTab === 'lore' }" href="#" @click.prevent="activeTab = 'lore'">Lore</a>
      </li>
    </ul>

    <!-- Description Tab Content -->
    <div v-if="activeTab === 'description'" class="tab-content">
      <span v-html="this.selectedItem.getDescriptionHTML()"></span>
    </div>

    <!-- Lore Tab Content -->
    <div v-if="activeTab === 'lore'" class="tab-content">
      <span v-html="this.selectedItem.getFlavorHTML()"></span>

    </div>
  </div>
</template>

<script>
import Item from "@/model/item.js";
import {config} from "@/model/config.js";

export default {
  name: "HomebrewDetails",
  props: {
    selectedItem: Item
  },
  data() {
    return {
      activeTab: 'description', // Default active tab
      imageExists: true,
      config: config
    };
  },
  methods: {
    highlightItemName(text) {
      if (!this.selectedItem || !this.selectedItem.name) return text;
      const regex = new RegExp(`(${this.selectedItem.name})`, 'gi');
      return text.replace(regex, '<span class="highlighted-item-name">$1</span>');
    },
    renderParagraphs(paragraphs, name) {
      function removePrefix(str, prefix) {
        if (str.startsWith(prefix)) {
          return str.slice(prefix.length);
        }
        return str;
      }
      function removeSuffix(str, suffix) {
        if (str.endsWith(suffix)) {
          return str.slice(0, -suffix.length);
        }
        return str;
      }

      let tableInProgress = false;
      let htmlResult = "";
      for(let paragraph of paragraphs) {
        if(!paragraph || !paragraph.text) continue
        const regex = new RegExp(`(${name})`, 'gi');
        let text = paragraph.text.replace(regex, '<span class="highlighted-item-name">$1</span>');
        if(text.startsWith('||')){
          if(tableInProgress) htmlResult += '</tbody></table>'
          const headers = text.split('||').map(h => `<th>${removeSuffix(removePrefix(h.trim(), '*'),'*')}</th>`).join('');
          htmlResult += `<table class="table table-sm table-responsive table-borderless table-striped"><thead><tr>${headers}</tr></thead><tbody>`;
          tableInProgress = true
          continue
        }
        if(text.startsWith('|') && tableInProgress){
          const columns = text.split('|').map(c => `<td>${c.trim()}</td>`).join('');
          htmlResult += `<tr>${columns}</tr>`;
          continue
        }
        if(tableInProgress) {
          tableInProgress = false
          htmlResult += '</tbody></table>';
        }
        htmlResult += `<p class="description-paragraph">`
        if(paragraph.headline) {
          htmlResult += `<span class="description-headline">${paragraph.headline}. </span>`
        }
        htmlResult += `<span>${text}</span>`
        htmlResult += `</p>`
      }
      return htmlResult
    }
  },
  watch: {
    selectedItem(newVal, oldVal) {
      if (oldVal && newVal && newVal.id !== oldVal.id) {
        this.imageExists = true; // Reset the flag when the item changes
      }
    }
  },
};
</script>

<style>
.data-container {
  flex: 35%;
  padding: 10px;
  text-align: left; /* Ensures all text in the container is aligned to the left */
}

.item-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.item-image-placeholder {
  width: 100%;        /* Width equal to the container's width */
  height: 0;          /* Initially set height to 0 */
  padding-bottom: 100%; /* Padding-bottom as a percentage of the width to create a square */
  position: relative;   /* Relative positioning for the inner content */
  border-radius: 5px;
}

.item-image-placeholder-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the content */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width of the parent */
  height: 100%; /* Full height of the parent */
  color: #6e6940;
  font-weight: bold;
}

.edit-button {
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-name {
  font-size: xx-large;
  font-weight: bold;
  flex-grow: 1; /* Ensure it takes up the remaining space */
}

.item-summary {
  font-style: italic;
  font-size: 1rem;
}

.tab-content .description-paragraph {
  margin: 0.5vh 0 0 0;
  padding: 0;
}

.tab-content .description-headline {
  font-weight: bold;
  font-style: italic;
}

.nav-tabs .nav-link {
  cursor: pointer; /* Makes the tab links appear clickable */
}

.tab-content {
  margin-top: 10px; /* Space between tabs and content */
}

.highlighted-item-name {
  font-style: italic;
}
</style>
