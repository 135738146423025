<template>
  <div class="jumbotron bg-danger-subtle">
    <h1 class="display-4">Unauthorized</h1>
    <p class="lead">You attempted to access content that you do not have the permission to see.</p>
    <hr class="my-4">
    <p>Make sure that you are logged in with an account that has sufficient privileges.</p>
    <p class="lead">
      <a class="btn btn-primary btn-lg" :href="`${config.apiURL}/auth/google/login`" role="button">Log in</a>
    </p>
  </div>
</template>

<script>
import {config} from "@/model/config.js";

export default {
  name: "JumbotronFallback401",
  data() {
    return {
      config: config,
    }
  }
}
</script>

<style scoped>

</style>