<template>
  <NavBar class="sticky-header"></NavBar>
  <br>
  <div class="bordered">
    <RouterView></RouterView>
  </div>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
export default {
  name: 'App',
  components: {
    NavBar,
  }
}
</script>

<style>
.bordered {
  margin: 0 0vw 0 0vw;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* optional, for better visibility */
  z-index: 100; /* ensures it stays on top of other content */
}

.sticky-header::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -100vh; /* Extend to the top of the page */
  bottom: 0;
  background-color: white;
  z-index: -1; /* Place it behind the content of the sticky header */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
