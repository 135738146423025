class Config {
    constructor(jsonStr) {
        let obj = {};
        try {
            obj = JSON.parse(jsonStr);
        }
        catch (e){
            console.warn("Error when parsing config JSON string:", e, "Using defaults");
            obj.apiURL = "https://api.greengobletgames.com"
        }
        this.apiURL = obj.apiURL;
    }
}

export const config = new Config(process.env.GGG_HOMEBREW_DASHBOARD_CONFIG)