<template>
  <span :class="['badge', rarityClass]">{{ rarity }}</span>
</template>

<script>
export default {
  name: "RarityPill",
  props: {
    rarity: {
      type: String,
      default: 'common',
      validator: (value) => ['common', 'uncommon', 'rare', 'very rare', 'legendary', 'artifact'].includes(value),
    }
  },
  computed: {
    rarityClass() {
      switch (this.rarity) {
        case 'common':
          return 'bg-color-common';
        case 'uncommon':
          return 'bg-color-uncommon';
        case 'rare':
          return 'bg-color-rare';
        case 'very rare':
          return 'bg-color-very-rare';
        case 'legendary':
          return 'bg-color-legendary';
        case 'artifact':
          return 'bg-color-artifact';
        default:
          return 'bg-color-common';
      }
    }
  }
}
</script>

<style scoped>
.badge {
  display: inline-block;
  min-width: 100px; /* Adjust based on your requirements */
  text-align: center;
  font-size: small;
}
</style>
