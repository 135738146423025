<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand bordered-left" href="/">
      <img src="https://i.imgur.com/Pr7Hv1X.png" width="30" height="30" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse bordered-right" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="/">Home</a>
        </li>
        <li v-if="!!this.loggedInCookieValue" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Homebrew
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="/homebrew/items">Items</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto">
        <li v-if="!!this.loggedInCookieValue" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{this.loggedInCookieValue}}
          </a>
          <div class="dropdown-menu dropdown-menu-end w-100" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="" @click.prevent="logout">Log out</a>
          </div>
        </li>
        <li v-if="!this.loggedInCookieValue" class="nav-item active">
          <a class="btn btn-primary" :href="`${config.apiURL}/auth/google/login`" role="button">Log in</a>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
import axios from "axios";
import router from "@/router";
import {config} from "@/model/config.js";

export default {
  name: "NavBar",
  data(){
    return {
      loggedInCookieValue: null,
      loggedInCookieName: 'logged-in-as',
      loggedInCookieCheckInterval: null,
      tokenRefreshInterval: null,
      tokenRefreshInProgress: false,
      tokenCookieName: 'jwt-token',
      config: config,
    }
  },
  methods: {
    setLoggedInCookie(value){
      if(value === this.loggedInCookieValue) return
      if(value) this.startRefreshTokenInterval();
      if(!value) this.stopRefreshTokenInterval();
      this.loggedInCookieValue = value;
    },
    logout(){
      axios.get(config.apiURL+'/auth/logout', {withCredentials: true})
          .then(() => {
            router.push("/")
          })
          .catch(error => {
            console.error('Error logging out:', error);
            this.setLoggedInCookie(null);
          })
          .finally(() => {
            this.setLoggedInCookie(this.getCookie(this.loggedInCookieName));
          });
    },
    refreshToken() {
      if (this.tokenRefreshInProgress) return
      this.tokenRefreshInProgress = true;
      axios.get(config.apiURL+'/auth/refresh', {withCredentials: true})
          .then(() => {
          })
          .catch(() => {
            this.logout()
          })
          .finally(() => {
            this.tokenRefreshInProgress = false;
          });
    },
    startRefreshTokenInterval(){
      this.tokenRefreshInterval = setInterval(() => {
        this.refreshToken();
      }, 30000); // checks every 30000 milliseconds (5 minutes)
    },
    stopRefreshTokenInterval(){
      if(this.tokenRefreshInterval){
        clearInterval(this.tokenRefreshInterval);
      }
    },
    getCookie(cookieName) {
      return document.cookie
          .split('; ')
          .find(row => row.startsWith(cookieName + '='))
          ?.substring(cookieName.length+1);
    },
    deleteCookie(cookieName) {
      document.cookie = cookieName +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    startLoggedInCookieCheckInterval() {
      this.loggedInCookieCheckInterval = setInterval(() => {
        this.setLoggedInCookie(this.getCookie(this.loggedInCookieName));
      }, 5000); // checks every 5000 milliseconds (5 seconds)
    },
    stopLoggedInCookieCheckInterval() {
      if (this.loggedInCookieCheckInterval) {
        clearInterval(this.loggedInCookieCheckInterval);
      }
    },
  },
  mounted() {
    this.setLoggedInCookie(this.getCookie(this.loggedInCookieName));
    this.startLoggedInCookieCheckInterval();
  },
  beforeUnmount() {
    this.stopLoggedInCookieCheckInterval();
  },
}
</script>

<style scoped>
.bordered-left {
  margin: 0 0 0 5vw;
}
.bordered-right {
  margin: 0 5vw 0 0;
}
</style>