import { createRouter, createWebHistory } from 'vue-router'
import HomebrewTableView from "@/views/HomebrewTableView.vue";
import HomeView from "@/views/HomeView.vue";
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path:"/homebrew/items",
            name:"Items",
            component: HomebrewTableView
        },
        {
            path:"/",
            name:"Home",
            component: HomeView
        },
    ]
})
export default router
