<template>
  <div style="text-align: left;">
    <form>
      <div class="sticky-header">
        <div class="space-betweener">
          <div class="centered-column">
            <div class="fw-bold fs-1">{{editableItem.name}}</div>
          </div>

          <div class="form-group row padded-form-group centered-column">
            <div class="col-sm-10">
              <button type="button" class="btn btn-primary" @click.prevent="() => {
                console.log(editableItem); console.log(editableItem.keyDiff(selectedItem));
                this.$emit('update-item', this.editableItem);
              }" :disabled="editableItem.jsonEqual(selectedItem)">
                {{
                  editableItem.jsonEqual(selectedItem) ? "Unchanged" : "Save"
                }}
              </button>
            </div>
<!--            <div v-if="selectedItem && editableItem && editableItem.keyDiff(selectedItem).length > 0">-->
<!--              <div class="text-muted smallest-font">{{"Changed: " + editableItem.keyDiff(selectedItem)}}</div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="item-summary">{{editableItem ? editableItem.summary() : ""}}</div>
      </div>
      <div class="padded-body">
        <div class="form-group row padded-form-group">
          <label for="itemName" class="col-sm-2 col-form-label fw-bold">Name</label>
          <div class="col-sm-10">
            <input class="form-control" id="itemName" placeholder="e.g. Ring of Example Giving" v-model="editableItem.name">
          </div>
        </div>
        <div class="form-group row padded-form-group">
          <label for="itemType" class="col-sm-2 col-form-label fw-bold">Type</label>
          <div class="col-sm-10">
            <select id="itemType" class="form-control" v-model="editableItem.type">
              <option v-for="(type, index) in editableItem.types()" :key="index">{{type}}</option>
            </select>
          </div>
        </div>
        <div v-if="editableItem.subtypes().length > 0" class="form-group row padded-form-group">
          <label for="itemSubtype" class="col-sm-2 col-form-label fw-bold">Subtype</label>
          <div class="col-sm-10">
            <select id="itemSubtype" class="form-control" v-model="editableItem.subtype">
              <option v-for="(subtype, index) in editableItem.subtypes()" :key="index">{{subtype}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row padded-form-group">
          <label for="itemRarity" class="col-sm-2 col-form-label fw-bold">Rarity</label>
          <div class="col-sm-10">
            <select id="itemRarity" class="form-control" v-model="editableItem.rarity">
              <option v-for="(rarity, index) in editableItem.rarities()" :key="index">{{rarity}}</option>
            </select>
          </div>
        </div>
        <div class="form-group row padded-form-group">
          <div class="col-sm-2 fw-bold">Requires Attunement</div>
          <div class="col-sm-10">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="itemRequiresAttunement" v-model="editableItem.attunement.isRequired">
              <label class="form-check-label" for="itemRequiresAttunement"></label>
            </div>
          </div>
        </div>

        <br>

        <!-- Tab Navigation -->
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'description' }" @click="activeTab = 'description'">Description</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'flavor' }" @click="activeTab = 'flavor'">Flavor</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'art' }" @click="activeTab = 'art'">Art</a>
          </li>
        </ul>

        <!-- Description Tab -->
        <div v-if="activeTab === 'description'">
          <div class="space-betweener">
            <div  class="form-group row padded-form-group" style="width: 45%;">
              <label for="itemDescription" class="fw-bold">Description</label>
              <textarea ref="descriptionTextArea" class="form-control" id="itemDescription" rows="10" v-model="this.descriptionText"></textarea>
            </div>
            <div v-if="this.descriptionText && this.descriptionText.length !== 0" ref="descriptionHTML" style="width: 45%;">
              <label class="fw-bold">Preview</label>
              <span v-html="this.descriptionHTML"></span>
            </div>
          </div>
        </div>

        <!-- Flavor Tab -->
        <div v-if="activeTab === 'flavor'">
          <div class="space-betweener">
            <div class="form-group row padded-form-group" style="width: 45%;">
              <label for="itemFlavor" class="fw-bold">Flavor</label>
              <textarea ref="flavorTextArea" class="form-control" id="itemFlavor" rows="10" v-model="this.flavorText"></textarea>
            </div>
            <div v-if="this.flavorText && this.flavorText.length !== 0" ref="flavorHTML" style="width: 45%;">
              <label class="fw-bold">Preview</label>
              <span v-html="this.flavorHTML"></span>
            </div>
          </div>
        </div>

        <!-- Art Tab -->
        <div v-if="activeTab === 'art'">
          <div class="form-group padded-form-group">
            <div class="space-betweener">
              <label for="itemIllustration" class="fw-bold" style="margin-right: 3.5vw">Upload Image</label>
              <select id="itemType" class="form-control" style="max-width: 20vw" v-model="this.fileInputType">
                <option v-for="(type, index) in this.fileInputTypes" :key="index">{{type}}</option>
              </select>
              <input type="file" class="form-control-file" id="itemIllustration" ref="fileInput" @change="handleFileUpload">
              <button class="btn btn-primary mt-2" @click.prevent="sendUploadedImage" :disabled="!this.uploadedImage">Upload</button>
            </div>
          </div>
        </div>

        <div v-if="editableItem.annotations && activeTab !== 'art'">

          <div class="form-group row padded-form-group">
            <label for="itemAttachedSpells" class="col-sm-2 col-form-label fw-bold">Attached Spells</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(attachedSpell, index) in editableItem.annotations.attachedSpells"
                  :key="index"
                  :tag="attachedSpell"
                  :index="index"
                  @remove-tag="removeAttachedSpellTag"
              ></DamageTag>
              <input class="form-control tag-input" placeholder="Add a tag..." @keyup.enter="addAttachedSpellTag" v-model="newAttachedSpellTag">
            </div>
          </div>

          <div class="form-group row padded-form-group">
            <label for="itemAttachedCreatures" class="col-sm-2 col-form-label fw-bold">Attached Creatures</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(attachedCreature, index) in editableItem.annotations.attachedCreatures"
                  :key="index"
                  :tag="attachedCreature"
                  :index="index"
                  @remove-tag="removeAttachedCreatureTag"
              ></DamageTag>
              <input class="form-control tag-input" placeholder="Add a tag..." @keyup.enter="addAttachedCreatureTag" v-model="newAttachedCreatureTag">
            </div>
          </div>

          <div  class="form-group row padded-form-group">
            <div class="col-sm-2 fw-bold">Sentient</div>
            <div class="col-sm-10">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="itemIsSentient" v-model="editableItem.annotations.isSentient">
                <label class="form-check-label" for="itemIsSentient"></label>
              </div>
            </div>
          </div>

          <div  class="form-group row padded-form-group">
            <div class="col-sm-2 fw-bold">Cursed</div>
            <div class="col-sm-10">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="itemIsCursed" v-model="editableItem.annotations.isCursed">
                <label class="form-check-label" for="itemIsCursed"></label>
              </div>
            </div>
          </div>

<!--          <div class="form-group row padded-form-group">-->
<!--            <label for="itemDamageResistances" class="col-sm-2 col-form-label fw-bold">Damage Resistances</label>-->
<!--            <div class="col-sm-10">-->
<!--              <input class="form-control" id="itemDamageResistances" :value=""v-model="editableItem.annotations.damageResistance">-->
<!--            </div>-->
<!--          </div>-->

          <div class="form-group row padded-form-group">
            <label for="itemCharges" class="col-sm-2 col-form-label fw-bold">Charges #</label>
            <div class="col-sm-10">
              <input type="number" class="form-control" id="itemCharges" placeholder="e.g. 3" v-model="editableItem.annotations.charges">
            </div>
          </div>

          <div v-if="editableItem.annotations.charges > 0" class="form-group row padded-form-group">
            <label for="itemRechargesOn" class="col-sm-2 col-form-label fw-bold">Recharges On</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(rechargeEvent, index) in editableItem.annotations.rechargesOn"
                  :key="index"
                  :tag="rechargeEvent"
                  :index="index"
                  @remove-tag="removeRechargesOnTag"
              ></DamageTag>
              <input class="form-control tag-input" list="recharge-events" placeholder="Add a tag..." @keyup.enter="addRechargesOnTag" v-model="newRechargesOnTag">
              <datalist id="recharge-events">
                <option v-for="(rechargeEvent, index) in this.rechargeEvents" :key="index" :value="rechargeEvent"></option>
              </datalist>
            </div>
          </div>

          <div v-if="editableItem.annotations.charges > 0 && editableItem.annotations.rechargesOn.length > 0" class="form-group row padded-form-group">
            <label for="itemRechargeAmount" class="col-sm-2 col-form-label fw-bold">Recharge Amount</label>
            <div class="col-sm-10">
              <input class="form-control" id="itemRechargeAmount" placeholder="e.g. 1d4" v-model="editableItem.annotations.rechargeAmount">
            </div>
          </div>

          <div class="form-group row padded-form-group">
            <label for="itemDamageResistances" class="col-sm-2 col-form-label fw-bold">Damage Resistances</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(resistance, index) in editableItem.annotations.damageResistance"
                  :key="index"
                  :tag="resistance"
                  :index="index"
                  @remove-tag="removeDamageResistanceTag"
              ></DamageTag>
              <input class="form-control tag-input" list="damage-options" placeholder="Add a tag..." @keyup.enter="addDamageResistanceTag" v-model="newDamageResistanceTag">
              <datalist id="damage-options">
                <option v-for="(damageType, index) in this.damageTypes" :key="index" :value="damageType"></option>
              </datalist>
            </div>
          </div>

          <div class="form-group row padded-form-group">
            <label for="itemDamageVulnerabilities" class="col-sm-2 col-form-label fw-bold">Damage Vulnerabilities</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(vulnerability, index) in editableItem.annotations.damageVulnerability"
                  :key="index"
                  :tag="vulnerability"
                  :index="index"
                  @remove-tag="removeDamageVulnerabilityTag"
              ></DamageTag>
              <input class="form-control tag-input" list="damage-options" placeholder="Add a tag..." @keyup.enter="addDamageVulnerabilityTag" v-model="newDamageVulnerabilityTag">
              <datalist id="damage-options">
                <option v-for="(damageType, index) in this.damageTypes" :key="index" :value="addDamageVulnerabilityTag"></option>
              </datalist>
            </div>
          </div>

          <div class="form-group row padded-form-group">
            <label for="itemDamageImmunities" class="col-sm-2 col-form-label fw-bold">Damage Immunities</label>
            <div class="col-sm-10 tag-input-container">
              <DamageTag
                  v-for="(immunity, index) in editableItem.annotations.damageImmunity"
                  :key="index"
                  :tag="immunity"
                  :index="index"
                  @remove-tag="removeDamageImmunityTag"
              ></DamageTag>
              <input class="form-control tag-input" list="damage-options" placeholder="Add a tag..." @keyup.enter="addDamageImmunityTag" v-model="newDamageImmunityTag">
              <datalist id="damage-options">
                <option v-for="(damageType, index) in this.damageTypes" :key="index" :value="damageType"></option>
              </datalist>
            </div>
          </div>

          <div v-if="editableItem.type === 'weapon'">
            <div class="form-group row padded-form-group">
              <label for="itemWeaponProperties" class="col-sm-2 col-form-label fw-bold">Weapon Properties</label>
              <div class="col-sm-10 tag-input-container">
                <DamageTag
                    v-for="(weaponProperty, index) in editableItem.annotations.properties"
                    :key="index"
                    :tag="weaponProperty"
                    :index="index"
                    @remove-tag="removeWeaponPropertyTag"
                ></DamageTag>
                <input class="form-control tag-input" list="weapon-properties" placeholder="Add a tag..." @keyup.enter="addWeaponPropertyTag" v-model="newWeaponPropertyTag">
                <datalist id="weapon-properties">
                  <option v-for="(weaponProperty, index) in this.weaponProperties" :key="index" :value="weaponProperty"></option>
                </datalist>
              </div>
            </div>

            <div class="form-group row padded-form-group">
              <label for="itemBaseDamage" class="col-sm-2 col-form-label fw-bold">Base Damage</label>
              <div class="col-sm-10">
                <input class="form-control" id="itemBaseDamage" placeholder="e.g. 1d8" v-model="editableItem.annotations.baseDamage">
              </div>
            </div>

            <div v-if="this.editableItem.annotations.properties.includes('versatile')" class="form-group row padded-form-group">
              <label for="itemVersatileDamage" class="col-sm-2 col-form-label fw-bold">Versatile Damage</label>
              <div class="col-sm-10">
                <input class="form-control" id="itemVersatileDamage" placeholder="e.g. 1d10" v-model="editableItem.annotations.versatileDamage">
              </div>
            </div>

            <div class="form-group row padded-form-group">
              <label for="itemDamageTypes" class="col-sm-2 col-form-label fw-bold">Damage Types</label>
              <div class="col-sm-10 tag-input-container">
                <DamageTag
                    v-for="(type, index) in editableItem.annotations.damageTypes"
                    :key="index"
                    :tag="type"
                    :index="index"
                    @remove-tag="removeDamageTypeTag"
                ></DamageTag>
                <input class="form-control tag-input" list="damage-options" placeholder="Add a tag..." @keyup.enter="addDamageTypeTag" v-model="newDamageTypeTag">
                <datalist id="damage-options">
                  <option v-for="(damageType, index) in this.damageTypes" :key="index" :value="damageType"></option>
                </datalist>
              </div>
            </div>

            <div class="form-group row padded-form-group">
              <label for="itemNormalRange" class="col-sm-2 col-form-label fw-bold">Normal Range</label>
              <div class="col-sm-10">
                <input type="number" class="form-control" id="itemNormalRange" placeholder="e.g. 60" v-model="editableItem.annotations.range.normal">
              </div>
            </div>

            <div v-if="this.editableItem.annotations.range.normal" class="form-group row padded-form-group">
              <label for="itemLongRange" class="col-sm-2 col-form-label fw-bold">Long Range</label>
              <div class="col-sm-10">
                <input type="number" class="form-control" id="itemLongRange" placeholder="e.g. 120" v-model="editableItem.annotations.range.long">
              </div>
            </div>

          </div>

          <div v-if="editableItem.type === 'armor'">
            <div class="form-group row padded-form-group">
              <label for="itemAC" class="col-sm-2 col-form-label fw-bold">Armor Class</label>
              <div class="col-sm-10">
                <input type="number" class="form-control" id="itemAC" placeholder="e.g. 18" v-model="editableItem.annotations.armorClass">
              </div>
            </div>
          </div>
        </div>
<!--        <div class="ender">-->
<!--          <div class="form-group row padded-form-group editor-button">-->
<!--            <div class="col-sm-10">-->
<!--              <button class="btn btn-primary">Validate</button>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="form-group row padded-form-group editor-button">-->
<!--            <div class="col-sm-10">-->
<!--              <button class="btn btn-primary">Save</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </form>
  </div>
</template>

<script>
import Item from "@/model/item";
import DamageTag from "@/components/DamageTag.vue";
import {damageTypes} from "@/model/item";
import {rechargeEvents} from "@/model/item";
import {weaponProperties} from "@/model/item";
import axios from "axios";
import {config} from "@/model/config.js"

export default {
  name: "HomebrewItemEditor",
  components: {DamageTag},
  props: {
    selectedItem: Item,
    isModalOpen: Boolean
  },
  computed: {
    descriptionText: {
      get() {
        return this.editableItem.getDescriptionText()
      },

      set(value){
        this.editableItem.setDescriptionText(value)
      }

    },
    flavorText: {
      get() {
        return this.editableItem.getFlavorText()
      },

      set(value){
        this.editableItem.setFlavorText(value)
      }
    },
  },
  data(){
    return {
      editableItem: new Item(),
      descriptionHTML: null,
      flavorHTML: null,
      activeTab: 'description', // Default active tab
      newDamageResistanceTag: '',
      newDamageVulnerabilityTag: '',
      newDamageImmunityTag: '',
      newDamageTypeTag: '',
      newRechargesOnTag: '',
      newWeaponPropertyTag: '',
      newAttachedSpellTag: '',
      newAttachedCreatureTag: '',
      damageTypes: damageTypes,
      rechargeEvents: rechargeEvents,
      weaponProperties: weaponProperties,
      uploadedImage: null,
      isFileSelected: false,
      loading: false,
      config: config,
      fileInputType: 'no-fx-illustration',
      fileInputTypes: [
          'card-front',
          'card-back',
          'fx-illustration',
          'no-fx-illustration',
          'flat',
          'sketch'
      ]
    }
  },
  methods: {
    async getSignedUrl(path) {
      try {
        const response = await axios.get(config.apiURL+'/homebrew/upload/image/items/'+this.editableItem.id+path, {withCredentials: true});
        return response.data.url;
      } catch (error) {
        console.error('Error getting signed URL', error);
        return null;
      }
    },
    async sendUploadedImage() {
      const file = this.uploadedImage;
      if (!file) return;

      function getFormattedDate() {
        const now = new Date();

        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}${hours}${minutes}${seconds}`;
      }

      const generalUploadURL = await this.getSignedUrl('/'+this.fileInputType+'/'+getFormattedDate()+'.png');
      if (!generalUploadURL) {
        console.error('No image upload signed URL provided');
        this.resetFileInput();
        return;
      }

      try {
        await axios.put(generalUploadURL, file, {
          headers: {
            'Content-Type': 'image/png'
          }
        });
        console.log('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file to GCS', error);
        this.resetFileInput();
        return;
      }

      const dashboardPreviewUploadURL = await this.getSignedUrl('/featured/dashboard-preview.png');
      if (!dashboardPreviewUploadURL) {
        console.error('No image upload signed URL provided');
        this.resetFileInput();
        return;
      }

      try {
        await axios.put(dashboardPreviewUploadURL, file, {
          headers: {
            'Content-Type': 'image/png'
          }
        });
        console.log('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading file to GCS', error);
      } finally {
        this.resetFileInput();
      }
    },
    resetFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = '';
      }
      this.uploadedImage = null;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.uploadedImage = e.target.result;
          // // Optionally, you can send the file to your backend here or via a separate method
          // await this.sendUploadedImage()
        };
        reader.readAsArrayBuffer(file);
      }
    },
    adjustDescriptionTextAreaHeight() {
      const htmlContent = this.$refs.descriptionHTML;
      let height = 0;
      if (htmlContent) height = htmlContent.clientHeight;
      if(this.$refs.descriptionTextArea) this.$refs.descriptionTextArea.style.height = `${height}px`;
    },
    adjustFlavorTextAreaHeight() {
      const htmlContent = this.$refs.flavorHTML;
      let height = 0;
      if (htmlContent) height = htmlContent.clientHeight;
      if(this.$refs.flavorTextArea) this.$refs.flavorTextArea.style.height = `${height}px`;
    },
    handleResize() {
      if (!this.isModalOpen) return
      this.adjustDescriptionTextAreaHeight()
      this.adjustFlavorTextAreaHeight()
    },
    addDamageResistanceTag() {
      if (this.damageTypes.includes(this.newDamageResistanceTag) && (!this.editableItem.annotations || !this.editableItem.annotations.damageResistance.includes(this.newDamageResistanceTag))) {
        this.editableItem.annotations.damageResistance.push(this.newDamageResistanceTag);
        this.newDamageResistanceTag = '';
      }
    },
    removeDamageResistanceTag(index) {
      this.editableItem.annotations.damageResistance.splice(index, 1);
    },
    addDamageVulnerabilityTag() {
      if (this.damageTypes.includes(this.newDamageVulnerabilityTag) && (!this.editableItem.annotations || !this.editableItem.annotations.damageVulnerability.includes(this.newDamageVulnerabilityTag))) {
        this.editableItem.annotations.damageVulnerability.push(this.newDamageVulnerabilityTag);
        this.newDamageVulnerabilityTag = '';
      }
    },
    removeDamageVulnerabilityTag(index) {
      this.editableItem.annotations.damageVulnerability.splice(index, 1);
    },
    addDamageImmunityTag() {
      if (this.damageTypes.includes(this.newDamageImmunityTag) && (!this.editableItem.annotations || !this.editableItem.annotations.damageImmunity.includes(this.newDamageImmunityTag))) {
        this.editableItem.annotations.damageImmunity.push(this.newDamageImmunityTag);
        this.newDamageImmunityTag = '';
      }
    },
    removeDamageImmunityTag(index) {
      this.editableItem.annotations.damageImmunity.splice(index, 1);
    },
    addDamageTypeTag() {
      if (this.damageTypes.includes(this.newDamageTypeTag) && (!this.editableItem.annotations || !this.editableItem.annotations.damageTypes.includes(this.newDamageTypeTag))) {
        this.editableItem.annotations.damageTypes.push(this.newDamageTypeTag);
        this.newDamageTypeTag = '';
      }
    },
    removeDamageTypeTag(index) {
      this.editableItem.annotations.damageTypes.splice(index, 1);
    },
    addRechargesOnTag() {
      if (this.rechargeEvents.includes(this.newRechargesOnTag) && (!this.editableItem.annotations || !this.editableItem.annotations.rechargesOn.includes(this.newRechargesOnTag))) {
        this.editableItem.annotations.rechargesOn.push(this.newRechargesOnTag);
        this.newRechargesOnTag = '';
      }
    },
    removeRechargesOnTag(index) {
      this.editableItem.annotations.rechargesOn.splice(index, 1);
    },
    addWeaponPropertyTag() {
      if (this.weaponProperties.includes(this.newWeaponPropertyTag) && (!this.editableItem.annotations || !this.editableItem.annotations.properties.includes(this.newWeaponPropertyTag))) {
        this.editableItem.annotations.properties.push(this.newWeaponPropertyTag);
        this.newWeaponPropertyTag = '';
      }
    },
    removeWeaponPropertyTag(index) {
      this.editableItem.annotations.properties.splice(index, 1);
    },
    addAttachedSpellTag() {
      if ((!this.editableItem.annotations || !this.editableItem.annotations.attachedSpells.includes(this.newAttachedSpellTag))) {
        this.editableItem.annotations.attachedSpells.push(this.newAttachedSpellTag);
        this.newAttachedSpellTag = '';
      }
    },
    removeAttachedSpellTag(index) {
      this.editableItem.annotations.attachedSpells.splice(index, 1);
    },
    addAttachedCreatureTag() {
      if ((!this.editableItem.annotations || !this.editableItem.annotations.attachedCreatures.includes(this.newAttachedCreatureTag))) {
        this.editableItem.annotations.attachedCreatures.push(this.newAttachedCreatureTag);
        this.newAttachedCreatureTag = '';
      }
    },
    removeAttachedCreatureTag(index) {
      this.editableItem.annotations.attachedCreatures.splice(index, 1);
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  watch: {
    descriptionHTML() {
      this.$nextTick(() => {
        this.adjustDescriptionTextAreaHeight();
      });
    },
    flavorHTML() {
      this.$nextTick(() => {
        this.adjustFlavorTextAreaHeight();
      });
    },
    'editableItem.type': {
      handler(newType){
        if(newType === "weapon" || newType === "armor") return;
        this.editableItem.subtype = "";
      }
    },
    'editableItem.descriptionParagraphs': {
      handler(){
        this.descriptionHTML = this.editableItem.getDescriptionHTML();
      }
    },
    'editableItem.loreParagraphs': {
      handler(){
        this.flavorHTML = this.editableItem.getFlavorHTML();
      }
    },
    'editableItem.annotations.attachedSpells': {
      handler(){
        this.descriptionHTML = this.editableItem.getDescriptionHTML();
        this.flavorHTML = this.editableItem.getFlavorHTML();
      },
      deep: true,
    },
    'editableItem.annotations.attachedCreatures': {
      handler(){
        this.descriptionHTML = this.editableItem.getDescriptionHTML();
        this.flavorHTML = this.editableItem.getFlavorHTML();
      },
      deep: true
    },
    selectedItem: {
      handler(newVal){
        if(!newVal) return
        this.editableItem = new Item(JSON.parse(JSON.stringify(newVal)))
        this.$nextTick(() => {
          this.adjustDescriptionTextAreaHeight();
          this.adjustFlavorTextAreaHeight();
        });
      }
    },
    isModalOpen: {
      handler(newVal){
        if(!newVal) return
        this.$nextTick(() => {
          this.adjustDescriptionTextAreaHeight();
          this.adjustFlavorTextAreaHeight();
        });
      }
    },
    editableItem: {
      handler(){
        this.$nextTick(() => {
          this.adjustDescriptionTextAreaHeight();
          this.adjustFlavorTextAreaHeight();
        });
      }
    },
    activeTab: {
      handler(){
        this.$nextTick(() => {
          this.adjustDescriptionTextAreaHeight();
          this.adjustFlavorTextAreaHeight();
        });
      }
    }
  }
}
</script>

<style scoped>
.smallest-font{
  font-size: x-small;
}
.padded-form-group {
  padding-top: 2vh;
}
.centered-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.space-betweener{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ender{
  display: flex;
  justify-content: right;
  align-items: center;
}

.editor-button {
  margin: 0.25vh 0.5vw 0.25vh 0.5vw;
}

.item-summary {
  font-style: italic;
  font-size: 1rem;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white; /* optional, for better visibility */
  z-index: 100; /* ensures it stays on top of other content */
  padding: 1% 5% 2% 5%
}

.sticky-header::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -100vh; /* Extend to the top of the page */
  bottom: 0;
  background-color: white;
  z-index: -1; /* Place it behind the content of the sticky header */
}

.padded-body {
  padding: 0 5% 5% 5%
}

.tag-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag-input {
  flex: 1;
  border: none;
  outline: none;
}
</style>