import {capitalize} from "vue";

export class Reference {
    constructor(data) {
        if(!data) {
            this.source = "";
            this.page = 0;
            return
        }
        this.source = data.source;
        this.page = data.page;
    }
}

export class Paragraph {
    constructor({ text = "", headline = "" } = {}) {
        this.text = text;
        this.headline = headline;
    }
}


export class Attunement {
    constructor(data) {
        if(!data) {
            this.isRequired = false;
            this.canBeAttunedBy = [];
            return
        }
        this.isRequired = data.isRequired;
        this.canBeAttunedBy = data.canBeAttunedBy;
    }
}

export class SpellcastingFocus {
    constructor(data) {
        if(!data) {
            this.isSpellcastingFocus = false;
            this.canBeUsedBy = [];
            return
        }
        this.isSpellcastingFocus = data.isSpellcastingFocus;
        this.canBeUsedBy = data.canBeUsedBy;
    }
}

export class AbilityModifier {
    constructor({ modifyBy = 0, setTo = 0 } = {}) {
        this.modifyBy = modifyBy;
        this.setTo = setTo;
    }
}


export class Ability {
    constructor({
                    strength = {},
                    dexterity = {},
                    constitution = {},
                    intelligence = {},
                    wisdom = {},
                    charisma = {}
                } = {}) {
        this.strength = new AbilityModifier(strength);
        this.dexterity = new AbilityModifier(dexterity);
        this.constitution = new AbilityModifier(constitution);
        this.intelligence = new AbilityModifier(intelligence);
        this.wisdom = new AbilityModifier(wisdom);
        this.charisma = new AbilityModifier(charisma);
    }
}


export class Bonus {
    constructor({
                    spellAttack = "",
                    armorClass = "",
                    weapon = "",
                    weaponAttack = "",
                    weaponDamage = "",
                    savingThrow = "",
                    spellSaveDC = "",
                    proficiencyBonus = "",
                    abilityCheck = "",
                    weaponCritDamage = ""
                } = {}) {
        this.spellAttack = spellAttack;
        this.armorClass = armorClass;
        this.weapon = weapon;
        this.weaponAttack = weaponAttack;
        this.weaponDamage = weaponDamage;
        this.savingThrow = savingThrow;
        this.spellSaveDC = spellSaveDC;
        this.proficiencyBonus = proficiencyBonus;
        this.abilityCheck = abilityCheck;
        this.weaponCritDamage = weaponCritDamage;
    }
}


export class Range {
    constructor({ normal = 0, long = 0 } = {}) {
        this.normal = normal;
        this.long = long;
    }
}

export class ItemAnnotations {
    constructor({
                    ability = {},
                    attachedSpells = [],
                    attachedCreatures = [],
                    bonus = {},
                    charges = 0,
                    rechargesOn = "",
                    rechargeAmount = 0,
                    isCursed = false,
                    isSentient = false,
                    grantsLanguage = false,
                    grantsProficiency = false,
                    damageResistance = [],
                    damageImmunity = [],
                    damageVulnerability = [],
                    lootTables = [],
                    value = 0,
                    weight = 0,
                    usesAmmunition = false,
                    properties = [],
                    baseDamage = "",
                    versatileDamage = "",
                    damageTypes = [],
                    range = {},
                    shotsBeforeReload = 0,
                    armorClass = 0,
                    stealthDisadvantage = false,
                    minimumStrengthRequired = 0,
                    maximumDexterityBonus = 0
                } = {}) {
        this.ability = new Ability(ability);
        this.attachedSpells = attachedSpells ? attachedSpells : [];
        this.attachedCreatures = attachedCreatures ? attachedCreatures : [];
        this.bonus = new Bonus(bonus);
        this.charges = charges ? charges : 0;
        this.rechargesOn = rechargesOn ? rechargesOn : [];
        this.rechargeAmount = rechargeAmount ? rechargeAmount : "";
        this.isCursed = isCursed ? isCursed : false;
        this.isSentient = isSentient ? isCursed : false;
        this.grantsLanguage = grantsLanguage;
        this.grantsProficiency = grantsProficiency;
        this.damageResistance = damageResistance ? damageResistance : [];
        this.damageImmunity = damageImmunity ? damageImmunity : [];
        this.damageVulnerability = damageVulnerability ? damageVulnerability : [];
        this.lootTables = lootTables;
        this.value = value;
        this.weight = weight;
        this.usesAmmunition = usesAmmunition;
        this.properties = properties ? properties : [];
        this.baseDamage = baseDamage ? baseDamage : "";
        this.versatileDamage = versatileDamage ? versatileDamage : "";
        this.damageTypes = damageTypes ? damageTypes : [];
        this.range = new Range(range);
        this.shotsBeforeReload = shotsBeforeReload;
        this.armorClass = armorClass ? armorClass : 0;
        this.stealthDisadvantage = stealthDisadvantage;
        this.minimumStrengthRequired = minimumStrengthRequired;
        this.maximumDexterityBonus = maximumDexterityBonus;
    }
}


const rarities = [
    "common",
    "uncommon",
    "rare",
    "very rare",
    "legendary",
    "artifact",
];

const types = [
    "wondrous",
    "scroll",
    "ring",
    "potion",
    "rod",
    "weapon",
    "armor",
    "staff",
    "wand"
]

const weaponSubtypes = [
    "",
    "club",
    "dagger",
    "greatclub",
    "handaxe",
    "javelin",
    "light hammer",
    "mace",
    "quarterstaff",
    "sickle",
    "spear",
    "light crossbow",
    "dart",
    "shortbow",
    "sling",
    "battleaxe",
    "flail",
    "glaive",
    "greataxe",
    "greatsword",
    "halberd",
    "lance",
    "longsword",
    "maul",
    "morningstar",
    "pike",
    "rapier",
    "scimitar",
    "shortsword",
    "trident",
    "war pick",
    "warhammer",
    "whip",
    "blowgun",
    "hand crossbow",
    "heavy crossbow",
    "longbow",
    "net"
]

const armorSubtypes = [
    "",
    "padded",
    "leather",
    "studded leather",
    "hide",
    "chain shirt",
    "scale mail",
    "breastplate",
    "half plate",
    "ring mail",
    "chain mail",
    "splint",
    "plate",
    "shield",
]

export const damageTypes = [
    "fire",
    "bludgeoning",
    "radiant",
    "necrotic",
    "psychic",
    "cold",
    "force",
    "piercing",
    "lightning",
    "thunder",
    "acid",
    "poison",
    "slashing",
]

export const rechargeEvents = [
    "restShort",
    "restLong",
    "round",
    "turn",
    "midnight",
    "dawn",
    "dusk",
    "midday",
]

export const weaponProperties = [
    "versatile",
    "ammunition",
    "heavy",
    "special",
    "reload",
    "light",
    "thrown",
    "loading",
    "two-handed",
    "burstfire",
    "reach",
    "finesse"
]

export default class Item {
    constructor(data) {
        if (!data) {
            this.initializeEmptyItem();
            return;
        }
        this.id = data.id || '';
        this.name = data.name || '';
        this.referencedIn = data.referencedIn ? data.referencedIn.map(ref => new Reference(ref)) : [];
        this.rarity = data.rarity || '';
        this.descriptionParagraphs = data.descriptionParagraphs ? data.descriptionParagraphs.map(paragraph => new Paragraph(paragraph)) : [];
        this.loreParagraphs = data.loreParagraphs ? data.loreParagraphs.map(paragraph => new Paragraph(paragraph)) : [];
        this.attunement = data.attunement ? new Attunement(data.attunement) : new Attunement();
        this.spellcastingFocus = data.spellcastingFocus ? new SpellcastingFocus(data.spellcastingFocus) : new SpellcastingFocus();
        this.annotations = data.annotations ? new ItemAnnotations(data.annotations) : new ItemAnnotations();
        this.type = data.type || '';
        this.subtype = data.subtype || '';
    }

    jsonEqual(other) {
        return JSON.stringify(this) === JSON.stringify(other);
    }

    keyDiff(other) {
        return keyDiff(this, other)
    }

    initializeEmptyItem() {
        this.id = '';
        this.name = '';
        this.referencedIn = [];
        this.rarity = '';
        this.descriptionParagraphs = [];
        this.loreParagraphs = [];
        this.attunement = new Attunement();
        this.spellcastingFocus = new SpellcastingFocus();
        this.annotations = new ItemAnnotations();
        this.type = '';
        this.subtype = '';
    }

    displayType() {
        if(this.subtype) return capitalize(this.type) + " (" + this.subtype + ")"
        return capitalize(this.type)
    }

    summary() {
        let str = this.displayType() + ", " + this.rarity
        if (this.attunement && this.attunement.isRequired) {
            str += " (requires attunement"
            if (this.attunement.canBeAttunedBy && this.attunement.canBeAttunedBy.length > 0) {
                str += " by " + this.attunement.canBeAttunedBy
            }
            str += ")"
        }
        return str
    }

    getDescriptionHTML(){
        return paragraphs2HTML(this.descriptionParagraphs, this.name,
            this.annotations.attachedSpells, this.annotations.attachedCreatures)
    }

    getFlavorHTML(){
        return paragraphs2HTML(this.loreParagraphs, this.name,
            this.annotations.attachedSpells, this.annotations.attachedCreatures)
    }

    getDescriptionText(){
        return paragraphs2Text(this.descriptionParagraphs)
    }

    setDescriptionText(descriptionText){
        this.descriptionParagraphs = text2Paragraphs(descriptionText)
    }

    getFlavorText(){
        return paragraphs2Text(this.loreParagraphs)
    }

    setFlavorText(flavorText){
        this.loreParagraphs = text2Paragraphs(flavorText)
    }

    rarities() {
        return rarities;
    }

    types() {
        return types;
    }

    damageTypes() {
        return damageTypes;
    }

    subtypes(){
        if(this.type === "weapon") return weaponSubtypes;
        if(this.type === "armor") return armorSubtypes;
        return [];
    }
}

function paragraphs2Text(paragraphs) {
    if(!paragraphs || paragraphs.length === 0) return "";
    let text = "";
    for(let paragraph of paragraphs){
        if(paragraph.headline) text+="*"+paragraph.headline+"* ";
        if(paragraph.text) text+=paragraph.text;
        text+="\n";
    }
    text = removeSuffix(text, '\n')
    text = removePrefix(text, '\n')
    return text;
}

function countSubstringOccurrence(string, subString) {
    // Escape special characters in the substring
    const escapedSubString = subString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSubString, 'g');

    // Match the substring in the main string using the regex
    const matches = string.match(regex);

    // Return the number of matches, or 0 if no matches found
    return matches ? matches.length : 0;
}

function text2Paragraphs(text) {
    if (!text) return [];
    let paragraphs = [];
    let parts = text.split("\n");
    for (let part of parts) {
        let paragraphHeadline = "";
        let paragraphText = "";
        if (part.startsWith("*") && countSubstringOccurrence(part, "*") >= 2) {
            let r = part.substring(1).trim().split("*", 2); // Adjusted split logic
            paragraphHeadline = r[0].trim(); // Ensure headline is trimmed
            if (r.length > 1) {
                paragraphText = r[1].trim(); // Only assign if r[1] exists
            }
        } else {
            paragraphText = part;
        }
        paragraphs.push(new Paragraph({text: paragraphText, headline: paragraphHeadline})); // Ensure keys match the Paragraph class structure
    }
    return paragraphs;
}

function removePrefix(str, prefix) {
    if (str.startsWith(prefix)) {
        return str.slice(prefix.length);
    }
    return str;
}
function removeSuffix(str, suffix) {
    if (str.endsWith(suffix)) {
        return str.slice(0, -suffix.length);
    }
    return str;
}

function keyDiff(obj1, obj2) {
    let diff = [];
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    allKeys.forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(obj1, key) || !Object.prototype.hasOwnProperty.call(obj2, key)) {
            diff.push(key); // Key is not present in both objects
        } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
            diff.push(key); // Values are different
        }
    });

    return diff;
}

function paragraphs2HTML(paragraphs, name, attachedSpells, attachedCreatures) {

    let tableInProgress = false;
    let htmlResult = "";
    for(let paragraph of paragraphs) {
        if(!paragraph || !paragraph.text) continue
        let text = paragraph.text
        if(name){
            const regex = new RegExp(`(\\b${name}\\b)`, 'gi');
            text = text.replace(regex, '<span style="font-style: italic">$1</span>');
        }
        for(let attachedSpell of attachedSpells) {
            const regex = new RegExp(`(\\b${attachedSpell}\\b)`, 'gi');
            text = text.replace(regex, '<span style="font-style: italic; color: #b700ff; font-weight: bold">$1</span>');
        }
        for(let attachedCreature of attachedCreatures) {
            const regex = new RegExp(`(\\b${attachedCreature}\\b)`, 'gi');
            text = text.replace(regex, '<span style="font-style: italic; color: #ff7e00; font-weight: bold">$1</span>');
        }
        if(text.startsWith('||')){
            if(tableInProgress) htmlResult += '</tbody></table>'
            const headers = text.split('||').map(h => `<th>${removeSuffix(removePrefix(h.trim(), '*'),'*')}</th>`).join('');
            htmlResult += `<table class="table table-sm table-responsive table-borderless table-striped"><thead><tr>${headers}</tr></thead><tbody>`;
            tableInProgress = true
            continue
        }
        if(text.startsWith('|') && tableInProgress){
            const columns = text.split('|').map(c => `<td>${c.trim()}</td>`).join('');
            htmlResult += `<tr>${columns}</tr>`;
            continue
        }
        if(tableInProgress) {
            tableInProgress = false
            htmlResult += '</tbody></table>';
        }
        htmlResult += `<p style="margin: 0.5vh 0 0 0; padding: 0;">`
        if(paragraph.headline) {
            htmlResult += `<span style="font-weight: bold; font-style: italic">${paragraph.headline}. </span>`
        }
        htmlResult += `<span>${text}</span>`
        htmlResult += `</p>`
    }
    return htmlResult
}