<template>
<h1 class="head">Admin Dashboard</h1>
</template>

<script>
export default {
  name: "HomeView"
}
</script>

<style scoped>
.head {
  margin: 20vh 15vw 20vh 15vw;
  font-weight: bold;
  color: #ababab;
}
</style>